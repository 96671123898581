import { ErrorMessage, Field, useFormikContext } from 'formik'
import React from 'react'
import "./Input.css"

const Input = ({ name, label, type = "", placeholder = "#", value, onChange, required, error, onBlur, ...props }) => {
    const { errors, touched } = useFormikContext();
    const showError = errors[name] && touched[name];

    // console.log(showError);

    return (
        <div className={`form-floating mb-3`}>
            <Field name={name} type={type} autoComplete={"off"} className={`form-control ${showError ? "is-invalid" : ""}`} placeholder={placeholder} {...props} />
            <label>
                {label || name}
                {required && <span className='required'>*</span>}
            </label>
            <ErrorMessage name={name} component="span" className="input-erro" />
        </div>
    )
}

export default Input