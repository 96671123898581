const usuarioEstaAutenticado = () => {
    const usuario = obterUsuarioAutenticado();

    return usuario != null;
}

const obterUsuarioAutenticado = () => {
    const usuario = localStorage.getItem("user");

    if (!usuario) {
        return null;
    }

    return JSON.parse(usuario);
}

const salvarUsuarioAutenticado = (user) => {
    localStorage.setItem("user", JSON.stringify(user));
}

export {
    usuarioEstaAutenticado,
    obterUsuarioAutenticado,
    salvarUsuarioAutenticado
}