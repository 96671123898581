import React from 'react'
import BreadcrumbLoja from '../../../components/breadcrumb/BreadcrumbLoja';
import { obterBreadcrumbStatusPedido } from '../../../Util/BreadcrumbUtil';
import "./../Style.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faCreditCard } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';

const DadosEntrega = () => {
    const navigate = useNavigate();

    const irParaFinalizar = () => {
        navigate("finalizar");
    }
    const itensBreadcrumb = obterBreadcrumbStatusPedido(1);

    return (
        <div className='finalizar position-relative'>
            <div className='row'>
                <div className="col-12 mt-3">
                    <BreadcrumbLoja itens={itensBreadcrumb} />
                </div>

                <hr />

                <div className="col-12">

                    <div className="card card-customizado shadow-sm">
                        <div className="card-body">
                            <p className='mb-3 texto-primario fw-bold'>Endereço de entrega</p>

                            <div className="info">
                                <p>Itaocara, RJ - CEP 28570000, Centro</p>
                                <p>Daniel Pereira Sanches - (22) 99699-8989</p>
                            </div>
                        </div>
                    </div>

                    <div className="card card-customizado shadow-sm mt-3">
                        <div className="card-body">
                            <p className='mb-3 texto-primario fw-bold'>Taxa de entrega</p>

                            <div className="card-opcao-select mb-2">
                                <div className="row">
                                    <div className='col-1 d-flex justify-content-center'>
                                        <input type='radio' />
                                    </div>
                                    <div className="col-11">
                                        <p>Goiás, GO - R$ 9,00</p>
                                    </div>
                                </div>
                            </div>

                            <div className="card-opcao-select mb-2 selecionado">
                                <div className="row">
                                    <div className='col-1 d-flex justify-content-center'>
                                        <input type='radio' selected />
                                    </div>
                                    <div className="col-11">
                                        <p>Itaocara, RJ - R$ 12,00</p>
                                    </div>
                                </div>
                            </div>

                            <div className="card-opcao-select mb-2">
                                <div className="row">
                                    <div className='col-1 d-flex justify-content-center'>
                                        <input type='radio' />
                                    </div>
                                    <div className="col-11">
                                        <p>São paulo, SP - R$ 13,00</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='row info-totalPagamento px-1 py-3 d-flex justify-content-between align-items-center'>
                <div className="col-4">
                    <p>Total</p>
                    <p className='total-pagar'>R$ 121,00</p>
                </div>
                <div className="col-8 d-flex justify-content-end">
                    <button className='btn btn-outline-light fw-bold' onClick={irParaFinalizar}>Pagamento &nbsp;{<FontAwesomeIcon icon={faArrowRight} />}</button>
                </div>
            </div>
        </div>
    )
}

export default DadosEntrega;