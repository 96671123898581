import React, { useContext, useEffect, useState } from 'react';
import "./Cesta.css";
import BreadcrumbLoja from '../../components/breadcrumb/BreadcrumbLoja';
import ItemCesta from './ItemCesta';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { obterBreadcrumbStatusPedido } from '../../Util/BreadcrumbUtil';
import { useNavigate } from 'react-router-dom';

const Cesta = () => {
    const [cesta, setCesta] = useState([]);

    const navigate = useNavigate();

    useEffect(() => {
        // setCesta(obterCesta());
    }, []);


    const irParaDadosEntrega = () => {
        navigate("entrega");
    }

    const itensBreadcrumb = obterBreadcrumbStatusPedido(0);

    let itensCesta = [
        {
            id: 1,
            descricao: "Analgésico para aliviar a dor",
            img: "https://www.drogariaminasbrasil.com.br/media/product/9e6/dipirona-monoidratada-1g-com-10-comprimidos-generico-prati-donaduzzi-ed2.jpg",
            preco: 19.99,
            precoPromocao: 2.99,
            fabricante: "Farmácia ABC"
        },
    ];
    return (
        <div className='container-cesta'>
            <div className='cesta'>

                <div className='row'>
                    <div className="col-12 mt-3">
                        <BreadcrumbLoja itens={itensBreadcrumb} />
                    </div>

                    <hr />
                </div>

                <div className='row'>
                    <p className='qtd-itens-cesta mb-2'>{itensCesta.length} itens na cesta</p>

                    {itensCesta.map(item => (
                        <div className='col-12 mb-2' key={item.id}>
                            <ItemCesta item={item} />
                        </div>
                    ))}
                </div>



            </div>
            <div className='row info-totalPagamento px-1 py-3 d-flex justify-content-between align-items-center'>
                <div className="col-4">
                    <p>Total</p>
                    <p className='total-pagar'>R$ 121,00</p>
                </div>
                <div className="col-8 d-flex justify-content-end">
                    <button className='btn btn-outline-light fw-bold' onClick={irParaDadosEntrega}>Dados da entrega &nbsp;{<FontAwesomeIcon icon={faArrowRight} />}</button>
                </div>
            </div>
        </div>
    )
}

export default Cesta;