import React from 'react'
import './Titulo.css';

const Titulo = (props) => {
  return (
    <>
      <h1 className={`titulo mb-2 ${props?.className}`}>{props?.titulo}</h1>
    </>
  )
}

export default Titulo