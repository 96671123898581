import React, { useContext } from 'react'
import "./Login.css";
import { Form, Formik } from 'formik';
import * as Yup from "yup";
import Titulo from './../../components/titulo/Titulo';
import Input from './../../components/input/Input';
import Error from '../../components/Error/Error';
import UserContext from '../../Contexts/UserContext';
import ButtonSecundario from '../../components/button/ButtonSecundario';
import EmpresaContext from '../../Contexts/EmpresaContext';


const Login = () => {
    const { empresa } = useContext(EmpresaContext);

    const { fazerLogin, loading, error } = useContext(UserContext);

    const initialValues = {
        login: "",
        senha: ""
    };

    const validationSchema = Yup.object({
        login: Yup.string()
            .required("Informe seu email"),
        senha: Yup.string()
            .required('Informe sua senha')
    });

    const handleSubmit = (values, { setSubmitting }) => {
        fazerLogin(values);
    };

    return (
        <>
            <Titulo titulo={'Acessar conta'}></Titulo>

            <div className='row'>
                <div className='col-12'>

                    <Error error={error} />

                    <Formik
                        onSubmit={handleSubmit}
                        initialValues={initialValues}
                        validationSchema={validationSchema}>
                        <Form>
                            <Input type='text' label={"Email"} name={"login"} required={true} placeholder='#' />
                            <Input type='password' label={"Senha"} name={"senha"} required={true} placeholder='#' />

                            <ButtonSecundario loading={loading} type='submit' className={'btn-lg w-100'}>
                                Entrar
                            </ButtonSecundario>
                        </Form>
                    </Formik>

                    <div className='text-end mt-1'>
                        <a href=''><small>Esqueceu sua senha?</small></a>
                    </div>

                    <hr />

                    <p className='text-muted text-center mb-2'>Acessar usando suas redes sociais</p>

                    <div className='d-flex justify-content-between'>
                        <a href={`/${empresa}/login`} className='btn btn-facebook d-flex justify-content-evenly align-items-center'><img width={16} height={16} src="https://cdn-icons-png.flaticon.com/512/733/733547.png?w=740&t=st=1685400735~exp=1685401335~hmac=12a609fa60a44ca5f8722024f445a849a7f3cdeabfa72e89b0e39862e66d75d4" alt="" /> Facebook</a>
                        <a href={`/${empresa}/login`} className='btn btn-google d-flex justify-content-evenly align-items-center'><img width={16} height={16} src="https://cdn-icons-png.flaticon.com/512/300/300221.png?w=740&t=st=1685400808~exp=1685401408~hmac=3359c33461c39cc79b063047a162eb697620999e00148538219f36fd623f9b66" alt="" /> Google</a>
                    </div>
                    <p className='text-muted text-center mt-4'>Não tem uma conta? <a className='text-uppercase link' href="cadastro">cadastre-se</a></p>
                </div>

            </div>
        </>
    )
}

export default Login;
