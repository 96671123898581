import React from 'react'
import Titulo from '../../components/titulo/Titulo'
import CardPedido from '../../components/pedidos/CardPedido'

const Pedidos = () => {
    const pedidos = [
        {
            numero: 423423432,
            status: 'Pendente',
            data: '2023-05-28',
            qtdItens: 3,
            formaPag: 'Cartão de crédito',
            total: 150.00
        },
        {
            numero: 324324234324,
            status: 'Concluído',
            data: '2023-05-29',
            qtdItens: 5,
            formaPag: 'Boleto bancário',
            total: 250.00
        },
        {
            numero: 242342233576,
            status: 'Cancelado',
            data: '2023-05-30',
            qtdItens: 2,
            formaPag: 'Transferência bancária',
            total: 75.50
        },
        {
            numero: 367545745,
            status: 'Pendente',
            data: '2023-05-30',
            qtdItens: 1,
            formaPag: 'Pix',
            total: 30.00
        }
    ];

    for (let i = 0; i < pedidos.length; i++) {
        pedidos[i].data = converterDataFormatoBr(pedidos[i].data);
    }

    function converterDataFormatoBr(data) {
        const partes = data.split('-');
        return `${partes[2]}/${partes[1]}/${partes[0]}`;
    }


    return (
        <>
        <div className="card card-customizado shadow-sm p-3 mb-2">
            <Titulo className="text-start" titulo={'Meus pedidos'} ></Titulo>
            <p className='text-muted'>Acompanhe o status dos seus pedidos</p>
        </div>

            {/* <h6 className='text-center text-muted' >Exibindo {pedidos.length} resultados</h6> */}

            <div className='row'>
                <div className='col-12'>
                    <label htmlFor='selectOrdenar'>Ordenar por</label>
                    <select className="form-select mb-2" aria-label="Default select example" id='selectOrdenar'>
                        <option defaultValue>Data do pedido</option>
                        <option value="1">Valor</option>
                        <option value="2">Número do pedido</option>
                    </select>

                    {pedidos.map(p => <CardPedido key={p.numero} pedido={p} />)}
                </div>
            </div>
        </>
    )
}

export default Pedidos