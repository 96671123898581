import ReactOwlCarousel from 'react-owl-carousel';
import Titulo from '../titulo/Titulo';
import { CardProduto } from './card/CardProduto';
import "./OfertaProduto.css";

const OfertaProdutos = ({ titulo, produtos }) => {
    return (
        <>
            <div className='row mb-5'>
                <div className='col-12 p-0 m-0'>
                    <Titulo titulo={titulo} />
                    <hr />
                    <ReactOwlCarousel title={titulo} items={2} startPosition={1} loop center margin={8} nav={true}>
                        {produtos?.map(p =>
                            <CardProduto key={p.id} produto={p} />
                        )}
                    </ReactOwlCarousel>
                </div>
            </div>
        </>
    )
}

export default OfertaProdutos;