import React from 'react'
import "./css/homeSite.css";

const HomeSite = () => {
  return (
    <div>
      <section className="container my-5">
        <div className="text-white text-center header py-5">
          <h1>Bem-vindo ao RCA Store</h1>
          <p>Gerencie seu negócio com o melhor sistema de automação do mercado!</p>
        </div>
        <div className="text-center mb-4">
          <h2>Empresas que já utilizam o RCA Store</h2>
          <p>Confira algumas das empresas que confiam em nossa solução:</p>
        </div>
        <div className="row">
          <div className="col-md-4 col-sm-6 mb-3">
            <div className="client-card p-3 text-center border">
              <img src="https://assets.mypharma.com.br/662002153a6b3141ecd4af04/logo/8c847f81e829e569824fa1309368797acfd5643b-image.png" alt="Empresa 1" className="img-fluid rounded-circle rounded-circle mb-2" />
              <h5>EPharma</h5>
              <p>Descrição curta da empresa 1.</p>
            </div>
          </div>
          <div className="col-md-4 col-sm-6 mb-3">
            <div className="client-card p-3 text-center border">
              <img src="https://www.drogarias.net/imgempresas/unidas-farma-21-105152-5Vw6.jpg" alt="Empresa 2" className="img-fluid rounded-circle mb-2" />
              <h5>Unidas Farma 2</h5>
              <p>Descrição curta da empresa 2.</p>
            </div>
          </div>
          <div className="col-md-4 col-sm-6 mb-3">
            <div className="client-card p-3 text-center border">
              <img src="https://thumbs.dreamstime.com/z/logotipo-de-farm%C3%A1cia-m%C3%A9dica-cruzou-o-sa%C3%BAde-projeto-gr%C3%A1fico-vetorial-modelo-design-vetor-abstrato-m%C3%A3os-arquivo-ai-alta-207949206.jpg?ct=jpeg" alt="Empresa 3" className="img-fluid rounded-circle mb-2" />
              <h5>Farmácia do povo</h5>
              <p>Descrição curta da empresa 3.</p>
            </div>
          </div>
          <div className="col-md-4 col-sm-6 mb-3">
            <div className="client-card p-3 text-center border">
              <img src="https://assets.mypharma.com.br/662002153a6b3141ecd4af04/logo/8c847f81e829e569824fa1309368797acfd5643b-image.png" alt="Empresa 1" className="img-fluid rounded-circle rounded-circle mb-2" />
              <h5>EPharma</h5>
              <p>Descrição curta da empresa 1.</p>
            </div>
          </div>
          <div className="col-md-4 col-sm-6 mb-3">
            <div className="client-card p-3 text-center border">
              <img src="https://www.drogarias.net/imgempresas/unidas-farma-21-105152-5Vw6.jpg" alt="Empresa 2" className="img-fluid rounded-circle mb-2" />
              <h5>Unidas Farma 2</h5>
              <p>Descrição curta da empresa 2.</p>
            </div>
          </div>
          <div className="col-md-4 col-sm-6 mb-3">
            <div className="client-card p-3 text-center border">
              <img src="https://thumbs.dreamstime.com/z/logotipo-de-farm%C3%A1cia-m%C3%A9dica-cruzou-o-sa%C3%BAde-projeto-gr%C3%A1fico-vetorial-modelo-design-vetor-abstrato-m%C3%A3os-arquivo-ai-alta-207949206.jpg?ct=jpeg" alt="Empresa 3" className="img-fluid rounded-circle mb-2" />
              <h5>Farmácia do povo</h5>
              <p>Descrição curta da empresa 3.</p>
            </div>
          </div>
        </div>
      </section>

      <section className="cta-section bg-secondary text-white text-center py-5">
        <h2>Cadastre-se no RCA Store agora!</h2>
        <p>Tenha acesso a todas as funcionalidades exclusivas e melhore a gestão da sua empresa.</p>
        <a href="#cadastro" className="btn btn-light mt-3">Quero me Cadastrar</a>
      </section>

      <footer className="bg-dark text-white text-center py-3">
        <p>&copy; 2024 RCA Store. Todos os direitos reservados.</p>
      </footer>

      <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.3.0/dist/js/bootstrap.bundle.min.js"></script>
    </div>
  )
}

export default HomeSite;