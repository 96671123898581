import React, { useContext, useState } from 'react'
import "./NavLoja.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faHome, faInfoCircle, faSearch, faUser, faUserCircle } from '@fortawesome/fontawesome-free-solid';
import { faBasketShopping, faXmark } from '@fortawesome/free-solid-svg-icons';
import ButtonSecundario from '../button/ButtonSecundario';
import ButtonSemEstilo from '../button/ButtonSemEstilo';
import { Link } from 'react-router-dom';
import UserContext from '../../Contexts/UserContext';
import EmpresaContext from '../../Contexts/EmpresaContext';

const NavLoja = () => {
    const { user } = useContext(UserContext);
    const { empresa } = useContext(EmpresaContext);

    const msgSaudacaoUsuario = (user ? user?.primeiroNome : "Seja bem vindo") + "!";

    const [menuAberto, setMenuAberto] = useState(false);

    const toggleMenu = () => {
        setMenuAberto(!menuAberto);
    }

    return (
        <>
            <header>
                <nav className="navbar navbar-expand-lg navbar-dark py-2">
                    <div className="container-fluid">
                        <button onClick={toggleMenu} type="button" data-bs-toggle="collapse" data-bs-target="#navMenu">
                            {!menuAberto && (
                                <FontAwesomeIcon icon={faBars} />
                            )}
                            {menuAberto && (
                                <FontAwesomeIcon icon={faXmark} />
                            )}
                        </button>
                        <a href={`/${empresa}`}>
                            <img style={{width: '50px'}} className="navbar-brand mx-auto" src="https://rcasistemas.com.br/wp/wp-content/uploads/2024/07/logo-rca-sistemas-site.png#2051" />
                        </a>
                        <button className='btn my-auto position-relative'>
                            <a href={`/${empresa}/cesta`}>
                                <span className="position-absolute translate-middle badge rounded-pill qtd-cesta">
                                    3
                                </span>
                                <FontAwesomeIcon icon={faBasketShopping} />
                            </a>
                        </button>
                        <div className='nav-search d-flex justify-content-between align-items-center w-100 mt-2'>
                            <form className='position-relative w-100'>
                                <input type="text" className='form-control pesquisa' placeholder='Busca...' />
                                <FontAwesomeIcon icon={faSearch} />
                            </form>
                        </div>
                    </div>
                </nav>

                <div className="offcanvas offcanvas-start" id="navMenu" >
                    <div className="offcanvas-header">
                        <h2 className='h2 mt-3'>
                            <FontAwesomeIcon icon={faUserCircle} />
                            <span className='ms-3'>Olá, {msgSaudacaoUsuario}</span>
                        </h2>
                    </div>
                    <div className="offcanvas-body">
                        <ul className='opcoes'>
                            <a href={`/${empresa}`}>
                                <li>
                                    <FontAwesomeIcon icon={faHome} />&nbsp;&nbsp;Início
                                </li>
                            </a>
                            <a href={`/${empresa}/minha-conta`}>
                                <li>
                                    <FontAwesomeIcon icon={faUser} />&nbsp;&nbsp;Minha conta
                                </li>
                            </a>
                            <a href={`/${empresa}/pedidos`}>
                                <li>
                                    <FontAwesomeIcon icon={faBasketShopping} />&nbsp;&nbsp;Meus pedidos
                                </li>
                            </a>
                            <a href={`/${empresa}/sobre`}>
                                <li>
                                    <FontAwesomeIcon icon={faInfoCircle} />&nbsp;&nbsp;Sobre
                                </li>
                            </a>
                        </ul>
                        <div className='d-flex fixed-bottom mb-5'>
                            <div className='col-10 mx-auto'>
                                <ButtonSecundario className={'w-100 mb-3'}>
                                    <a href={`/${empresa}/login`}>
                                        Acessar minha conta
                                    </a>
                                </ButtonSecundario>
                                <ButtonSemEstilo className='w-100'>
                                    <Link to={`/${empresa}/cadastro`}>Quero me cadastrar</Link>
                                </ButtonSemEstilo>
                            </div>

                        </div>
                    </div>
                </div>
            </header>
        </>
    )
}

export default NavLoja;