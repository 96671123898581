import React from 'react'
import "./PageFooterLoja.css";

const PageFooterLoja = () => {
    return (
        <>
            <div className='container-fluid footer'>
                <footer className="d-flex flex-wrap justify-content-between align-items-center py-3 mt-4 border-top">
                    <div className="col-md-4 d-flex align-items-center">
                        <a href="/" className="mb-3 me-2 mb-md-0 text-muted text-decoration-none lh-1">
                            <img width={90} src='https://homolstore.rcasistemas.com.br/img/rca-logo-store-horizontal.png'/>
                        </a>
                        <small className="text-light">© 2023 Feito por R.C.A Sistemas</small>
                    </div>

                    <ul className="nav col-md-4 justify-content-end list-unstyled d-flex">
                    <li className="ms-3"></li>
                    <li className="ms-3"></li>
                    <li className="ms-3"></li>
                </ul>
                </footer>
            </div>
        </>

    )
}

export default PageFooterLoja;