import React, { createContext, useEffect, useState } from 'react';
import { obterEmpresaUrl } from '../Util/UrlUtil';

const EmpresaContext = createContext();

export const EmpresaStorage = ({ children }) => {
    const [empresa, setEmpresa] = useState(null);

    useEffect(() => {
        setEmpresa(obterEmpresaUrl());
    }, [])

    return (
        <EmpresaContext.Provider value={{ empresa }}>
            {children}
        </EmpresaContext.Provider >
    )
}

export default EmpresaContext