import "../node_modules/jquery/dist/jquery.min";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/bootstrap/dist/js/bootstrap.bundle.min";
import '../node_modules/@fortawesome/fontawesome-svg-core/styles.css'

import '../node_modules/owl.carousel/dist/assets/owl.carousel.min.css';
import '../node_modules/owl.carousel/dist/assets/owl.theme.default.min.css';

import { BrowserRouter, Route, Routes } from "react-router-dom";
import NavLoja from "./components/navbar/NavLoja";
import CarouselLoja from "./components/carousel/CarouselLoja";
import HomeSite from "./pages/home/HomeSite";
import PageFooterLoja from "./components/footer/PageFooterLoja";
import { obterEmpresaUrl } from "./Util/UrlUtil";
import Login from "./pages/login/Login";
import HomeLoja from "./pages/home/HomeLoja";
import Cadastro from "./pages/conta/Cadastro";

//estilos
import "./index.css";
import './App.css';
import Pedidos from "./pages/pedidos/Pedidos";
import Pedido from "./pages/pedidos/pedido/Pedido";
import DetalhesProduto from "./pages/produto/DetalhesProduto";
import Finalizar from "./pages/finalizar/Finalizar";
import DadosEntrega from "./pages/finalizar/entrega/DadosEntrega";
import PrivateRoute from "./Routes/PrivateRoute";
import MinhaConta from "./pages/conta/MinhaConta";
import { UserStorage } from "./Contexts/UserContext";
import { EmpresaStorage } from "./Contexts/EmpresaContext";
import Cesta from './pages/cesta/Cesta';


function App() {
  //esse método só é chamado aqui e em lugares onde não é um componente react
  const empresa = obterEmpresaUrl();

  const naHomeDaLoja = window.location.pathname.split("/").length > 2;

  return (
    <>
      <BrowserRouter>
        <EmpresaStorage>
          <UserStorage>
            {empresa &&
              <>
                <NavLoja empresa={empresa} />

                {!naHomeDaLoja && <CarouselLoja />}
              </>
            }

            <div className={empresa ? 'container my-3': ''}>

              <Routes>
                <Route path="/" element={<HomeSite />} />

                {/* ROTAS SEM AUTENTICAÇÃO */}
                <Route path="/:empresa/" element={<HomeLoja />} />
                <Route path="/:empresa/login" element={<Login />} />
                <Route path="/:empresa/cadastro" element={<Cadastro />} />
                <Route path="/:empresa/cesta" element={<Cesta />} />
                <Route path="/:empresa/produto/:id" element={<DetalhesProduto />} />


                {/* ROTAS COM AUTENTICAÇÃO */}

                <Route path="/:empresa/pedidos"
                  element={<PrivateRoute><Pedidos /></PrivateRoute>}>
                </Route>

                <Route path="/:empresa/pedido/:id"
                  element={<PrivateRoute><Pedido /></PrivateRoute>}>
                </Route>

                <Route path="/:empresa/entrega"
                  element={<PrivateRoute><DadosEntrega /></PrivateRoute>}>
                </Route>

                <Route path="/:empresa/finalizar"
                  element={<PrivateRoute><Finalizar /></PrivateRoute>}>
                </Route>

                <Route path="/:empresa/minha-conta"
                  element={<PrivateRoute><MinhaConta /></PrivateRoute>}>
                </Route>
              </Routes>

            </div>

            {empresa &&
              <>
                <PageFooterLoja />
              </>
            }
          </UserStorage>
        </EmpresaStorage>
      </BrowserRouter>
    </>
  );
}

export default App;
