import React from 'react'
import { obterIdProdutoUrl } from '../../Util/UrlUtil';
import "./DetalhesProduto.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown } from '@fortawesome/fontawesome-free-solid';
import ButtonPrimario from '../../components/button/ButtonPrimario';

const DetalhesProduto = () => {
  const idProduto = obterIdProdutoUrl();

  const emPromocao = () => {
    return produto.precoPromocao != produto.preco;
  }

  const produto = {
    id: 7,
    descricao: "Protetor solar FPS 50 para proteção contra raios UV",
    img: "https://images.tcdn.com.br/img/img_prod/981368/d_500_dipirona_sodica_50ml_3757_1_003426fdbdb78c9c4ac2a714664d1270.jpg",
    preco: 27.50,
    precoPromocao: 27.00,
    fabricante: "Farmácia RST"
  }

  return (
    <div className='detalhesProduto'>
      <div className='row'>
        <div className='col-12'>
          <div class="card border-0">
            <img src={produto.img} className="card-img-top" alt="..." />
            <div class="card-body text-center">
              <h5 class="card-text">{produto.descricao}</h5>
              <p>Fabricado por <strong className='fabricante'>{produto.fabricante}</strong></p>
              <p>Código: 23221</p>

              {emPromocao() && <div className='selo-promocao d-flex align-items-center justify-content-center my-2'>
                <span><FontAwesomeIcon icon={faArrowDown} bounce />&nbsp;35%</span>
              </div>}

              <div>
                {emPromocao() && <p className={'preco-desconto'}>R$ {produto.preco.toFixed(2).replace('.', ',')}</p>}
                <p className='preco'>{produto.precoPromocao.toFixed(2).replace('.', ',')}</p>
              </div>
              <hr />
              <div className='texto-descricao'>
                <p className='mt-3'>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Similique, laudantium assumenda atque dicta modi unde enim eveniet odio nesciunt nobis sequi at culpa fugiat dolore omnis dignissimos hic libero aut?</p>
              </div>
              <hr />

              <div className='div-quantidade'>
                <div className='d-flex flex-row justify-content-between align-items-center lbl-quantidade'>
                  <h6 className='text-muted fw-bold'>Quantidade</h6>
                  <div className='d-flex flex-column'>
                    <div className='d-flex align-items-center'>
                      <img loading="lazy" alt="Diminuir" src="https://cdn1.staticpanvel.com.br/cdn_service/svg/checkout-images/add-less.svg" class="img-btn"></img>
                      <input className='form-control form-control-sm p-2 mx-1' value={1} />
                      <img loading="lazy" alt="Adicionar" src="https://cdn1.staticpanvel.com.br/cdn_service/svg/checkout-images/add-more.svg" class="img-btn"></img>
                    </div>
                    <span className="qtd-disponivel">3 disponíveis</span>
                  </div>
                </div>
              </div>
              <ButtonPrimario className='w-100 mt-3'>Adicionar à cesta</ButtonPrimario>
              {/* <button className='btn btn-primario w-100 mt-2'>Comprar</button> */}
            </div>
          </div>
        </div>
      </div>
    </div >
  )
}

export default DetalhesProduto;