import React from 'react'
import { Animated } from 'react-animated-css';
import "./Error.css";

const Error = ({ error }) => {
    if (!error) return null;
    return (
        <Animated animationIn="headShake" animationOut="headShake" isVisible={true}>
            <div className="alert alert-danger text-center">
                {error}
            </div>
        </Animated>
    )
}

export default Error