import { faBasketShopping, faCreditCard, faTruck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const obterBreadcrumbStatusPedido = (idxAtivo = 0) => {
    const itensBreadcrumb = [
        { titulo: "Cesta", icone: <FontAwesomeIcon icon={faBasketShopping} /> },
        { titulo: "Endereço", icone: <FontAwesomeIcon icon={faTruck} /> },
        { titulo: "Pagamento", icone: <FontAwesomeIcon icon={faCreditCard} /> }
    ];

    for (let i = 0; i <= idxAtivo; i++) {
        itensBreadcrumb[i].emProgresso = true;
    }

    return itensBreadcrumb;
}

export {
    obterBreadcrumbStatusPedido
}