import React from 'react';
import { Field, ErrorMessage, useFormikContext } from 'formik';
import InputMask from 'react-input-mask';
import "./Input.css"

const MaskedInputField = ({ name, mask, label, type = "", placeholder = "#", required, maskChar = '_', ...props }) => {
    const { errors, touched } = useFormikContext();
    const showError = errors[name] && touched[name];

    return (
        <div className={`form-floating mb-3`}>
            <Field name={name}>
                {({ field }) => (
                    <InputMask
                        {...field}
                        mask={mask}
                        maskChar={maskChar}
                        onBlur={field.onBlur}
                        onChange={field.onChange}
                        type={type} autoComplete={"off"}
                        className={`form-control ${showError ? "is-invalid" : ""}`}
                        {...props}
                        placeholder={placeholder}
                    />
                )}

            </Field>
            <label>
                {label || name}
                {required && <span className='required'>*</span>}
            </label>
            <ErrorMessage name={name} component="span" className="input-erro" />
        </div>
    );
};

export default MaskedInputField;
