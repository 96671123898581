import api from "../api";
import { handleServerResponseError } from "../util/handleServerResponseError";
import { obterEmpresaUrl } from "../../Util/UrlUtil";
const empresa = obterEmpresaUrl();

const obterProdutos = async () => {
    try {
        const { data } = await api.get("/produto?empresa=" + empresa);
        return data;
    } catch (error) {
        handleServerResponseError(error);
    }
}

export const ProdutoService = {
    obterProdutos
}