import React from 'react'

const CarouselLoja = () => {
    return (
        <>
            <div className="carousel slide">
                <div className="carousel-inner">
                    <div className="carousel-item active">
                        <img src="https://drogariaspacheco.vteximg.com.br/arquivos/ids/347922/banner_recorrencia.jpg?v=636664626129470000" className="d-block w-100" alt="..." />
                    </div>
                    {/* <div className="carousel-item">
                        <img src="..." className="d-block w-100" alt="..." />
                    </div>
                    <div className="carousel-item">
                        <img src="..." className="d-block w-100" alt="..." />
                    </div> */}
                </div>
                {/* <button className="carousel-control-prev" type="button" data-bs-target="#carouselExample" data-bs-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next" type="button" data-bs-target="#carouselExample" data-bs-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Next</span>
                </button> */}
            </div>
        </>
    )
}

export default CarouselLoja;