import React, { useContext } from 'react';
import "./CardPedido.css";
import EmpresaContext from '../../Contexts/EmpresaContext';

const CardPedido = (props) => {
    const empresa = useContext(EmpresaContext);

    return (
        <>
            <div className='card card-customizado mb-3'>
                <div className='card-header d-flex justify-content-between'>
                    <h5>Pedido Nº: <span>{props.pedido.numero}</span></h5>
                    {!props.esconderLinkDetalhes && (<a href={`/${empresa}/pedido/${props.pedido.numero}`}>Ver detalhes</a>)}
                </div>
                <div className='card-body'>
                    <div className='d-flex justify-content-between'>
                        <strong>Status</strong>
                        <span>{props.pedido.status}</span>
                    </div>
                    <div className='d-flex justify-content-between'>
                        <strong>Realizado em</strong>
                        <span>{props.pedido.data}</span>
                    </div>
                    <div className='d-flex justify-content-between'>
                        <strong>Quantidade de itens</strong>
                        <span>{props.pedido.qtdItens}</span>
                    </div>
                    <div className='d-flex justify-content-between'>
                        <strong>Forma de pagamento</strong>
                        <span>{props.pedido.formaPag}</span>
                    </div>
                    <div className='d-flex justify-content-between'>
                        <strong>Total</strong>
                        <span>R$ {props.pedido.total}</span>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CardPedido