import React from 'react';
import "./Item.css";

const Item = (props) => {
    return (
        <>
            <div className='card shadow-sm mb-2'>
                <div className='card-body d-flex p-1'>
                    <img src={props.item.imagem} width={60} />
                    <div className='d-flex flex-column justify-content-evenly w-100'>
                        <p className='m-0'><a className='descricao-produto'><small className='d-block'>{props.item.descricao}</small></a></p>
                        <div className='d-flex justify-content-between fw-bold'>
                            <p className='m-0 d-flex align-items-center'>
                                <small className='qtd-produto'>{props.item.qtd} unidade(s)</small>
                            </p>
                            <p className='m-0 text-end me-2'>
                                {props.item.preco != props.item.precoDesconto &&
                                    <>
                                        <small className='d-block text-decoration-line-through preco-desconto'>R$ {props.item.preco.toFixed(2).replace('.', ',')}</small>
                                        <span className='d-block preco'>R$ {props.item.precoDesconto.toFixed(2).replace('.', ',')}</span>
                                    </>}
                                {props.item.preco == props.item.precoDesconto &&
                                    <>
                                        <span className='d-block preco'>R$ {props.item.preco.toFixed(2).replace('.', ',')}</span>
                                    </>}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Item