import React, { useContext } from 'react';
import "./CardProduto.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown } from '@fortawesome/fontawesome-free-solid';
import ButtonPrimario from '../../button/ButtonPrimario';
import { imagemAleatoria } from '../../../Util/___ProdutoImgTestUtil';
import { useNavigate } from 'react-router-dom';
import EmpresaContext from '../../../Contexts/EmpresaContext';
import { ProdutoHelper } from '../../../Util/helpers/ProdutoHelper/ProdutoHelper';

export const CardProduto = ({ produto }) => {
    const { empresa } = useContext(EmpresaContext);

    const navigate = useNavigate("");

    const verDetalhes = () => {
        navigate(`/${empresa}/produto/${produto.id}`);
    }

    const emPromocao = () => {
        return produto.precoPromocao != produto.preco;
    }

    return (
        <div className='card-produto' key={produto?.id}>
            <div className='detalhes-produto' onClick={verDetalhes}>
                <img className='img-produto img-fluid mx-auto my-2' src={imagemAleatoria()} />
                <div>
                    {emPromocao() && <div className='selo-promocao d-flex align-items-center justify-content-center my-2'>
                        <span><FontAwesomeIcon icon={faArrowDown} bounce />&nbsp;35%</span>
                    </div>}
                    <span className='descricao'>{produto.descricao}</span>

                    {emPromocao() ?
                        <p className='d-flex align-items-center justify-content-center'>
                            <span className='text-muted preco-promocao me-1'>R$ {produto?.precoVenda?.toFixed(2).replace('.', ',')}</span>
                            <span className='text-muted preco ms-2'>R$ {produto?.precoPromocao?.toFixed(2).replace('.', ',')}</span>
                        </p> :
                        <span className='text-muted preco d-block text-center'>R$ {produto?.preco?.toFixed(2).replace('.', ',')}</span>
                    }
                </div>
            </div>

            <ButtonPrimario onClick={()=> ProdutoHelper.adicionarNaCesta(produto)}>Adicionar à cesta</ButtonPrimario>
        </div>
    )
}
