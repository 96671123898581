import React from 'react'
import "./BreadcrumbLoja.css";
//objeto item deve ser = { ativo: true, titulo: "titulo", icone: "" }

const BreadcrumbLoja = ({ itens }) => {
    return (
        <>
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                    {itens?.map((item, index) =>
                        <>
                            <li key={item?.titulo} className={`breadcrumb-item ${item?.emProgresso ? 'active' : ''}`} aria-current="page">
                                <a >{item?.icone}</a>
                                <span className='titulo-breadcrumb d-block mt-2'>{item?.titulo}</span>
                            </li>
                            {index < itens?.length - 1 && <li className={`linha-horizontal ${item?.emProgresso && index < itens.filter(x => x?.emProgresso).length - 1 ? 'bg-primario' : ''}`}></li>}
                        </>
                    )}
                </ol>
            </nav>

        </>
    )
}

export default BreadcrumbLoja;