import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import ButtonLixeira from '../../components/button/ButtonLixeira';
// import "./Cesta.css";

const ItemCesta = ({ item }) => {
    return (
        <>
            <div className='card border-0 card-item-cesta px-2 shadow-sm position-relative'>

                <div className="card-body">
                    {/* <span className="position-absolute translate-middle badge rounded-pill qtd-cesta">
                        3
                    </span> */}
                    <div className='row d-flex justify-content-start'>
                        <div className='col-3'>
                            <img src={item.img} width={50} />
                        </div>
                        <div className='col-8 me-auto'>
                            <span className='descricao-item'>{item.descricao}</span>
                        </div>
                    </div>


                    <ButtonLixeira><FontAwesomeIcon icon={'trash'} /></ButtonLixeira>
                </div>

                <div className='card-footer bg-white'>
                    <div className="row d-flex justify-content-center align-items-center">
                        <div className="col-5">
                            <div className='d-flex'>
                                <img loading="lazy" alt="Diminuir" src="https://cdn1.staticpanvel.com.br/cdn_service/svg/checkout-images/add-less.svg" class="img-btn"></img>
                                <input className='form-control form-control-sm p-2 mx-1' value={1} />
                                <img loading="lazy" alt="Adicionar" src="https://cdn1.staticpanvel.com.br/cdn_service/svg/checkout-images/add-more.svg" class="img-btn"></img>
                            </div>
                        </div>

                        <div className="col-7 d-flex justify-content-end align-items-center">
                            <span className='preco-desconto me-1'>R$ {(item.preco + 5).toFixed(2).replace(".", ",")}</span>
                            <span className='preco'>R$ {item.preco.toFixed(2).replace(".", ",")}</span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ItemCesta;