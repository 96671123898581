import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useState } from "react";
import { faSpinner, faUser } from "@fortawesome/fontawesome-free-solid";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import Titulo from './../../components/titulo/Titulo';
import MaskedInputField from './../../components/input/MaskedInputField';
import { AccountService } from "../../api/services/accountService";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import "./Cadastro.css";
import EmpresaContext from "../../Contexts/EmpresaContext";

const Cadastro = () => {
    const { empresa } = useContext(EmpresaContext);

    const [requesting, setRequesting] = useState(false);
    const [cadastroFoiRealizado, setCadastroFoiRealizado] = useState(false);
    const [error, setError] = useState("");
    const [userCadastrado, setUserCadastrado] = useState({});

    const initialValues = {
        nome: "",
        cpf: "",
        // dataNascimento: "",
        telefone: "",
        email: "",
        senha: "",
        confirmarSenha: ""
    };

    const validationSchema = Yup.object({
        nome: Yup.string()
            .min(3, "O campo deve ter no mínimo 3 caracteres")
            .required("O campo nome é obrigatório"),
        cpf: Yup.string()
            .min(14, "CPF inválido")
            .required("O campo CPF é obrigatório")
            .matches(/^\d{3}\.\d{3}\.\d{3}-\d{2}$/, 'CPF inválido')
            .test('valid-cpf', 'CPF inválido', (value) => {
                if (!value) return true; // Permite campo vazio
                const cpf = value.replace(/[^\d]+/g, ''); // Remove caracteres não numéricos

                if (cpf.length !== 11) return false;

                let sum = 0;
                let remainder;

                for (let i = 1; i <= 9; i++) {
                    sum += parseInt(cpf.substring(i - 1, i)) * (11 - i);
                }

                remainder = (sum * 10) % 11;

                if (remainder === 10 || remainder === 11) remainder = 0;

                if (remainder !== parseInt(cpf.substring(9, 10))) return false;

                sum = 0;
                for (let i = 1; i <= 10; i++) {
                    sum += parseInt(cpf.substring(i - 1, i)) * (12 - i);
                }

                remainder = (sum * 10) % 11;

                if (remainder === 10 || remainder === 11) remainder = 0;

                if (remainder !== parseInt(cpf.substring(10, 11))) return false;

                return true;
            }),
        email: Yup.string()
            .email("E-mail inválido")
            .required("O campo email é obrigatório"),
        telefone: Yup.string()
            .transform((value) => (value ? value.replace(/[^\d]/g, '') : ''))
            .matches(/^\d{2}\d{5}\d{4}$/, 'Telefone inválido')
            .min(11, 'Telefone inválido')
            .max(11, 'Telefone inválido'),
        // dataNascimento: Yup.date()
        //     .max(new Date(), "Não é possível incluir uma data futura")
        //     .required("Informe sua data de nascimento"),
        senha: Yup.string()
            .min(8, "A senha deve ter no mínimo 8 caracteres")
            .required("O campo senha é obrigatório"),
        confirmarSenha: Yup.string()
            .oneOf([Yup.ref('senha'), null], 'As senhas devem ser iguais')
            .required('Confirme sua senha'),
    });

    const handleSubmit = (values, { setSubmitting }) => {
        // setSubmitting(true);
        setRequesting(true);

        const model = {
            nome: values.nome,
            telefone: values.telefone,
            cpf: values.cpf,
            user: {
                email: values.email,
                senha: values.senha,
                userName: values.email
            }
        }

        AccountService.criarConta(model).then(resp => {
            setRequesting(true);

            if (resp?.error) {
                setError(resp.error);
            } else if (resp.errors) {

            } else {
                setCadastroFoiRealizado(true);
                setUserCadastrado(resp);
            }

            setRequesting(false);
        });
    };

    return (
        <>
            {/* CADASTRO REALIZADO COM SUCESSO */}
            {cadastroFoiRealizado &&

                <div className="row">
                    <div className="col-12 px-4 d-flex flex-column align-items-center">
                        <FontAwesomeIcon icon={faCircleCheck} className="icone-sucesso" />

                        <h6 className="my-3"><strong>Seu cadastro foi realizado com sucesso!</strong></h6>

                        <div className="mt-2 mb-3">
                            <p className="text-center">{userCadastrado?.user?.email ?? "email@teste.com"}</p>

                            <p>Use este endereço de email para acessar sua conta.</p>
                        </div>


                        <a href={`login`} className="btn btn-secundario">Acessar minha conta</a>
                    </div>
                </div>

            }



            {/* CADASTRO EM PREENCHIMENTO */}
            {!cadastroFoiRealizado &&
                <>
                    <Titulo titulo={'Criar conta'} ></Titulo>

                    {error &&
                        <div className="alert alert-danger">{error}</div>
                    }

                    <div className='row'>
                        <div className='col-12'>
                            <Formik onSubmit={handleSubmit}
                                initialValues={initialValues}
                                validationSchema={validationSchema}>

                                <Form>
                                    <MaskedInputField name={"nome"} label={"Nome"} type={"text"} required={true} placeholder={"#"} />

                                    <MaskedInputField name={"cpf"} mask={"999.999.999-99"} label={"CPF"} type={"text"} required={true} placeholder={"#"} />

                                    {/* <MaskedInputField name={"dataNascimento"} label={"Data de nascimento"} required={true} type={"date"} placeholder={"#"} /> */}

                                    <MaskedInputField name={"telefone"} label={"Telefone"} mask={"(99) 99999-9999"} type={"text"} placeholder={"#"} />

                                    <MaskedInputField name={"email"} label={"Email"} required={true} type={"email"} placeholder={"#"} />

                                    <MaskedInputField name={"senha"} label={"Senha"} required={true} type={"password"} placeholder={"#"} />

                                    <MaskedInputField name={"confirmarSenha"} label={"Confime sua senha"} required={true} type={"password"} placeholder={"#"} />

                                    <button name="submit" disabled={requesting} type="submit" className="btn btn-lg btn-secundario w-100">
                                        {!requesting && <FontAwesomeIcon icon={faUser} />}
                                        {requesting && <FontAwesomeIcon icon={faSpinner} spin />}
                                        &nbsp;
                                        Cadastrar
                                    </button>
                                </Form>


                            </Formik>
                            <p className='text-muted text-center mt-4'>Já tem uma conta? <a className='text-uppercase link' href="login">Fazer login</a></p>
                        </div>

                    </div>
                </>
            }
        </>
    )
}

export default Cadastro;
