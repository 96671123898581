import React, {  useEffect, useState } from 'react';
import OfertaProdutos from '../../components/produto/OfertaProdutos';
import { ProdutoService } from '../../api/services/produtoService';

const HomeLoja = () => {
  const [produtos, setProdutos] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    ProdutoService.obterProdutos().then(resp => {
      setProdutos(resp);
      setLoading(false);
    });
  }, [loading]);

  return (
    <>
      <OfertaProdutos titulo='Promoções' produtos={produtos} />
      <OfertaProdutos titulo='Mais vendidos' produtos={produtos} />
    </>
  )
}

export default HomeLoja;
