import { Navigate } from 'react-router-dom';
import { usuarioEstaAutenticado } from '../Util/UserUtil';

function PrivateRoute({ children }) {
  let autenticado = usuarioEstaAutenticado();

  if (!autenticado) {
    return <Navigate to="/landing" replace />;
  }

  return children;
}

export default PrivateRoute;
