const obterEmpresaUrl = () =>{
    return window.location.pathname.split("/")[1];
}

const obterIdProdutoUrl = () =>{
    return window.location.pathname.split("/")[3];
}

export {
    obterEmpresaUrl,
    obterIdProdutoUrl
}