import React, { useState } from 'react'
import BreadcrumbLoja from '../../components/breadcrumb/BreadcrumbLoja';
import { obterBreadcrumbStatusPedido } from '../../Util/BreadcrumbUtil';
import Item from '../../components/produto/item/Item';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faCheck, faCheckCircle } from '@fortawesome/free-solid-svg-icons';

const Finalizar = () => {
    const [finalizado, setFinalizado] = useState(true);

    const itensBreadcrumb = obterBreadcrumbStatusPedido(2);

    const finalizarPedido = () => {
        setFinalizado(true);
    }

    const itens = [
        {
            imagem: "https://io.convertiez.com.br/m/drogalider/shop/products/images/33521/small/dipirona-monoidratada-500mg-prati-donaduzzi-com-30-comprimidos-generico_18950.png",
            qtd: 2,
            descricao: "Analgésico",
            preco: 15.99,
            precoDesconto: 12.99
        },
        {
            imagem: "https://www.natuphitus.com.br/wp-content/uploads/2021/03/antisseptico-bucal-total-action-500ml.jpg",
            qtd: 1,
            descricao: "Antisséptico bucal",
            preco: 9.99,
            precoDesconto: 8.49
        },
        {
            imagem: "https://d16w7cuzwgzfcy.cloudfront.net/Custom/Content/Products/17/46/174689_vitaclinical-vitamina-c-1100mg-fr-60-capsulas-p560077_z1_637793235789232440.jpg",
            qtd: 3,
            descricao: "Vitamina C",
            preco: 22.50,
            precoDesconto: 22.50
        },
        {
            imagem: "https://maxxieconomica.com/storage/photos/1/Products/webp/789935835_1.webp",
            qtd: 3,
            descricao: "Hidratante facial",
            preco: 29.90,
            precoDesconto: 29.90
        },
        {
            imagem: "https://redemachado.com.br/arquivos/300573.webp",
            qtd: 5,
            descricao: "Shampoo anticaspa",
            preco: 12.99,
            precoDesconto: 10.99
        },
        {
            imagem: "https://www.avon.com.br/assets/fit-in/720x720/center/middle/https%3A%2F%2Favongroup.vtexassets.com%2Farquivos%2Fids%2F161480%2FProtetor_Solar_2_em_1_Care_Sun_290.jpg%3Fv%3D637146044746670000",
            qtd: 2,
            descricao: "Protetor solar FPS 50",
            preco: 39.99,
            precoDesconto: 34.99
        }
    ];

    return (
        <>
            {!finalizado &&
                <>
                    <div className='row'>
                        <div className="col-12 mt-3">
                            <BreadcrumbLoja itens={itensBreadcrumb} />
                        </div>

                        <hr />

                        <div className="col-12 my-2">
                            <div className='card shadow-lg card-customizado'>
                                <div className='card-header bg-white'>
                                    <h4 className='text-muted d-block mt-2'>Resumo da sua compra</h4>
                                </div>

                                <div className='card-body pt-2'>

                                    <div className='d-flex justify-content-between'>
                                        <strong>Subtotal</strong>
                                        <span>R$ 10,00</span>
                                    </div>
                                    <div className='d-flex justify-content-between'>
                                        <strong className='texto-vermelho'>Descontos</strong>
                                        <span className='texto-vermelho'>- R$ 3,12</span>
                                    </div>
                                    <div className='d-flex justify-content-between'>
                                        <strong>Frete</strong>
                                        <span>R$ 2,00</span>
                                    </div>

                                    <div className="d-flex justify-content-between texto-primario fw-bold mt-2">
                                        <h5 className='fw-bold'>Total a pagar</h5>
                                        <h5 className='fw-bold'>R$ 121,00</h5>
                                    </div>

                                    <hr className='m-0' />

                                    <div className='mt-2 px-2'>
                                        <strong><p className='mb-2'>Itens do pedido</p></strong>
                                        {itens.map(item => <Item item={item} />)}

                                    </div>


                                </div>
                            </div>
                        </div>

                        <div className="col-12">
                            <div className="card shadow-lg card-customizado">
                                <div className='card-header bg-white'>
                                    <h4 className='text-muted d-block mt-2'>Pagamento</h4>
                                </div>

                                <div className="card-body">
                                    <div className="card-opcao-select mb-2">
                                        <div className="row">
                                            <div className='col-1 d-flex justify-content-center'>
                                                <input type='radio' />
                                            </div>
                                            <div className="col-11">
                                                <p>Goiás, GO - R$ 9,00</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="card-opcao-select mb-2 selecionado">
                                        <div className="row">
                                            <div className='col-1 d-flex justify-content-center'>
                                                <input type='radio' selected />
                                            </div>
                                            <div className="col-11">
                                                <p>Itaocara, RJ - R$ 12,00</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="card-opcao-select mb-2">
                                        <div className="row">
                                            <div className='col-1 d-flex justify-content-center'>
                                                <input type='radio' />
                                            </div>
                                            <div className="col-11">
                                                <p>São paulo, SP - R$ 13,00</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='row info-totalPagamento px-1 py-3 d-flex justify-content-between align-items-center'>
                        <div className="col-4">
                            <p>Total</p>
                            <p className='total-pagar'>R$ 121,00</p>
                        </div>
                        <div className="col-8 d-flex justify-content-end">
                            <button className='btn btn-outline-light fw-bold' onClick={finalizarPedido}>Finalizar pedido &nbsp;{<FontAwesomeIcon icon={faArrowRight} />}</button>
                        </div>
                    </div>
                </>
            }


            {finalizado &&

                <>
                    <div className="row">
                        <div className="col-12">
                            <div className="card card-customizado card-pedido-finalizado">
                                <div className="card-body">
                                    <p><strong className='d-block texto-azul mb-3'>Pedido finalizado com sucesso</strong></p>
                                    <p className='text-muted'>Em breve você receberá um email contendo as informações do pedido.</p>

                                    <div className="card pedido-aprovado border-0 mt-3">
                                        <div className="card-body">
                                            <p><FontAwesomeIcon icon={faCheckCircle} />&nbsp; Pedido aprovado</p>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </>
            }

        </>
    )
}

export default Finalizar;