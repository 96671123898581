import { ProdutoService } from "../../../api/services/produtoService";

const adicionarNaCesta = (produto, qtd) => {
    const novaCesta = [];
    const produtoExistente = novaCesta.find((item) => item.id === produto.id);

    if (produtoExistente) {
        produtoExistente.qtd += qtd;
    } else {
        novaCesta.push({
            id: produto.id,
            descricao: produto.descricao,
            qtd,
        });
    }

    // setCesta(novaCesta);
    localStorage.setItem("cesta", JSON.stringify(novaCesta));

    console.log(novaCesta);
};

const obterCesta = () => {
    const cestaData = localStorage.getItem("cesta");
    const cesta = cestaData ? JSON.parse(cestaData) : [];
    // setCesta(cesta);
    return cesta;
};

const obterProdutos = () => {
    // setLoading(true);

    ProdutoService
        .obterProdutos()
        .then((resp) => {
            if (resp) {
                // setProdutos(resp);
            }
        })
        .finally(() => {
            // setLoading(false);
        });
};

export const ProdutoHelper = {
    adicionarNaCesta,
    obterCesta,
    obterProdutos
}