import React from 'react';
import CardPedido from '../../../components/pedidos/CardPedido';
import Titulo from '../../../components/titulo/Titulo';
import "./Pedido.css";
import Item from '../../../components/produto/item/Item';

const Pedido = () => {
    const pedido = {
        numero: 423423432,
        status: 'Pendente',
        data: '2023-05-28',
        qtdItens: 3,
        formaPag: 'Cartão de crédito',
        total: 150.00
    }

    const itens = [
        {
          imagem: "https://io.convertiez.com.br/m/drogalider/shop/products/images/33521/small/dipirona-monoidratada-500mg-prati-donaduzzi-com-30-comprimidos-generico_18950.png",
          qtd: 2,
          descricao: "Analgésico",
          preco: 15.99,
          precoDesconto: 12.99
        },
        {
          imagem: "https://www.natuphitus.com.br/wp-content/uploads/2021/03/antisseptico-bucal-total-action-500ml.jpg",
          qtd: 1,
          descricao: "Antisséptico bucal",
          preco: 9.99,
          precoDesconto: 8.49
        },
        {
          imagem: "https://d16w7cuzwgzfcy.cloudfront.net/Custom/Content/Products/17/46/174689_vitaclinical-vitamina-c-1100mg-fr-60-capsulas-p560077_z1_637793235789232440.jpg",
          qtd: 3,
          descricao: "Vitamina C",
          preco: 22.50,
          precoDesconto: 22.50
        },
        {
          imagem: "https://maxxieconomica.com/storage/photos/1/Products/webp/789935835_1.webp",
          qtd: 3,
          descricao: "Hidratante facial",
          preco: 29.90,
          precoDesconto: 29.90
        },
        {
          imagem: "https://redemachado.com.br/arquivos/300573.webp",
          qtd: 5,
          descricao: "Shampoo anticaspa",
          preco: 12.99,
          precoDesconto: 10.99
        },
        {
          imagem: "https://www.avon.com.br/assets/fit-in/720x720/center/middle/https%3A%2F%2Favongroup.vtexassets.com%2Farquivos%2Fids%2F161480%2FProtetor_Solar_2_em_1_Care_Sun_290.jpg%3Fv%3D637146044746670000",
          qtd: 2,
          descricao: "Protetor solar FPS 50",
          preco: 39.99,
          precoDesconto: 34.99
        }
      ];

    return (
        <>
            <div className='row'>
                <div className='col-12'>
                    <Titulo titulo={"Detalhes do pedido"} />
                    <CardPedido pedido={pedido} esconderLinkDetalhes={true} />

                    <div className='card card-customizado mb-3'>
                        <div className='card-header d-flex justify-content-between'>
                            <h5>Entrega</h5>
                        </div>
                        <div className='card-body'>
                            <div className='d-flex justify-content-between'>
                                <strong>Valor frete</strong>
                                <span>R$ 10,00</span>
                            </div>
                            <div className='d-flex justify-content-between'>
                                <strong>Endereço</strong>
                                <span>Travessa Thaumaturgo de Azevedo</span>
                            </div>
                            <div className='d-flex justify-content-between'>
                                <strong>Bairro</strong>
                                <span>Ipase</span>
                            </div>
                            <div className='d-flex justify-content-between'>
                                <strong>Cidade</strong>
                                <span>Rio Branco</span>
                            </div>
                            <div className='d-flex justify-content-between'>
                                <strong>CEP</strong>
                                <span>69900-339</span>
                            </div>
                            <div className='d-flex justify-content-between'>
                                <strong>Complemento</strong>
                                <span>Número 25</span>
                            </div>
                        </div>
                    </div>

                    <div className='px-1'>
                        <strong><p style={{ marginBottom: "3px" }}>Itens do pedido</p></strong>

                        {itens.map(item => <Item item={item}/>)}
                    </div>
                </div>
            </div>
        </>
    )
}

export default Pedido