const handleServerResponseError = (error) => {
    if (typeof error?.response?.data == "string") return {
        error: error?.response?.data ?? "Ocorreu um erro desconhecido"
    };

    //Erros do fluent validation no backend (caso queira apenas o primeiro chamar -> "obterPrimeiroErroFluentValidation(error?.response?.data.errors)")
    return error?.response?.data;
}

const obterPrimeiroErroFluentValidation = (validationErrors) => {
    for (let prop in validationErrors) {
        if (validationErrors.hasOwnProperty(prop)) {
            return {error: validationErrors[prop][0]};
        }
    }
}

export {
    handleServerResponseError,
    obterPrimeiroErroFluentValidation
}
