import api from "../api";
import { handleServerResponseError } from "../util/handleServerResponseError";

const criarConta = async (model) => {
    try {
        const { data } = await api.post("/account/create", model);
        return data;
    } catch (error) {
        return handleServerResponseError(error);
    }
}

const login = async (model) => {
    try {
        const { data } = await api.post("/account/login", model);
        return data;
    } catch (error) {
        return handleServerResponseError(error);
    }
}

export const AccountService = {
    criarConta,
    login
}