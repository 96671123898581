import React, { createContext, useCallback, useEffect, useState } from 'react';
import { AccountService } from "../api/services/accountService";
import { useNavigate } from 'react-router-dom';

const UserContext = createContext();

export const UserStorage = ({ children }) => {
    const [user, setUser] = useState(null);
    const [autenticado, setAutenticado] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const navigate = useNavigate();

    const userLogout = useCallback(function () {
        setUser(null);
        setError(null);
        setLoading(false);
        setAutenticado(false);

        navigate("/login");
    });

    function obterTokenLocalStorage() {
        const token = JSON.stringify(localStorage.getItem("token"));

        return token;
    }

    function obterUsuarioAutenticado(){
        var token = obterTokenLocalStorage();
    }

    async function fazerLogin(model) {
        try {
            setLoading(true);

            const resp = await AccountService.login(model);

            if (!resp) throw new Error('Erro desconhecido!');

            if (resp?.error) {
                setError(resp.error);
                return;
            }

            salvarTokenLocalmente(resp);
            navigate("empresa");

        } catch (err) {
            setError(err.message);
            setAutenticado(false)
        } finally {
            setLoading(false);
        }
    }

    function salvarTokenLocalmente(token) {
        localStorage.setItem(token);
    }


    useEffect(() => {
        async function autoLogin() {
            const token = obterTokenLocalStorage();

            if (token) {
            }
        }

        autoLogin();
    }, [userLogout]);


    return (
        <UserContext.Provider value={{ fazerLogin, userLogout, user, error, loading, autenticado }}>
            {children}
        </UserContext.Provider>
    )
}

export default UserContext