const imagemAleatoria = () => {
    const imagens = [
        "https://www.drogariaminasbrasil.com.br/media/product/9e6/dipirona-monoidratada-1g-com-10-comprimidos-generico-prati-donaduzzi-ed2.jpg",
        "https://global.cdn.magazord.com.br/farmamais/img/2023/03/produto/168/dipirona-mono-sol-100ml-1-850x1000.png?ims=600x600",
        "https://d1xdssnpeez8k0.cloudfront.net/Custom/Content/Products/10/85/10853_dipirona-monoidratada-medley-50mg-ml-caixa-com-1-frasco-com-100ml-de-solucao-de-uso-oral-1-copo-medidor-p7896422506243_z1_637217820567270211.jpg",
        "https://images.petz.com.br/fotos/1654521042966.jpg",
        "https://araujo.vteximg.com.br/arquivos/ids/4148683-1000-1000/07896714214603.jpg?v=637794241433330000",
        "https://images.tcdn.com.br/img/img_prod/981368/d_500_dipirona_sodica_50ml_3757_1_003426fdbdb78c9c4ac2a714664d1270.jpg",
        "https://io.convertiez.com.br/m/drogalider/shop/products/images/20631/large/benegrip-500-30-2mg-blister-com-6-comprimidos-revestidos_13417.jpg",
        "https://rededor.vtexassets.com/arquivos/ids/200453-800-800?v=638079331413270000&width=800&height=800&aspect=true",
        "https://www.drogariaminasbrasil.com.br/media/product/9e6/dipirona-monoidratada-1g-com-10-comprimidos-generico-prati-donaduzzi-ed2.jpg",
        "https://global.cdn.magazord.com.br/farmamais/img/2023/03/produto/168/dipirona-mono-sol-100ml-1-850x1000.png?ims=600x600",
        "https://d1xdssnpeez8k0.cloudfront.net/Custom/Content/Products/10/85/10853_dipirona-monoidratada-medley-50mg-ml-caixa-com-1-frasco-com-100ml-de-solucao-de-uso-oral-1-copo-medidor-p7896422506243_z1_637217820567270211.jpg",
        "https://images.petz.com.br/fotos/1654521042966.jpg",
        "https://araujo.vteximg.com.br/arquivos/ids/4148683-1000-1000/07896714214603.jpg?v=637794241433330000",
        "https://images.tcdn.com.br/img/img_prod/981368/d_500_dipirona_sodica_50ml_3757_1_003426fdbdb78c9c4ac2a714664d1270.jpg"
    ];

    // Gerar um índice aleatório para selecionar uma imagem da matriz
    const indiceAleatorio = Math.floor(Math.random() * imagens.length);

    // Retornar a imagem aleatória
    return imagens[indiceAleatorio];
};

export { imagemAleatoria }