import { faRightToBracket, faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

const ButtonSecundario = ({ children, className, loading, ...props }) => {
    return (
        <button {...props} className={`btn btn-secundario ${className ?? ''}`}>
            {!loading ? <FontAwesomeIcon icon={faRightToBracket} /> : <FontAwesomeIcon icon={faSpinner} spin />}
            &nbsp;
            {children}
        </button>
    )
}

export default ButtonSecundario